<template>
    <div>
        <div class="crumbs">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item>
                    <i class="el-icon-lx-cascades">告警設定</i>
                </el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <el-row class="add-btn">
            <el-col>
                <el-button type="primary"
                           @click="toPage('createTgAlert')">新增告警<i class="el-icon-lx-add el-icon--right"></i></el-button>
            </el-col>
        </el-row>
        <div class="container">
            <TableTemplate :colums="colums"
                           v-loading="listLoading"
                           :data="tableData">
                <!-- operate -->
                <template v-slot:expire_remind="data">
                    {{data.data.expire_remind.toString()}}
                </template>
                <!-- operate -->
                <template v-slot:operate="data">
                    <el-button size="mini"
                               icon="el-icon-edit"
                               @click="toPage('editTgAlert', data.data ,'edit')">編輯</el-button>
                    <el-button size="mini"
                               icon="el-icon-alarm-clock"
                               :loading="tgLoading"
                               @click="tgTest(data.data)">測試告警</el-button>
                    <el-button size="mini"
                               icon="el-icon-delete"
                               class="red"
                               @click="handleDelete(data.data)">删除</el-button>
                </template>
            </TableTemplate>
        </div>
    </div>
</template>

<script>
import { alertData, telegramTest, alertDeleteRobot } from '../../../api/index'
import TableTemplate from '../../../components/TableTemplate.vue'
export default {
    components: {
        TableTemplate,
    },
    data() {
        return {
            tableData: [],
            colums: [
                // {
                //     name: '機器人Id',
                //     prop: 'id',
                // },
                {
                    name: '群組Id',
                    prop: 'group_id',
                },
                {
                    name: '機器人名稱',
                    prop: 'robot_name',
                },
                {
                    name: '到期提醒',
                    type: 'slot',
                    attribute: { slotName: 'expire_remind' },
                },
                {
                    name: '到期提醒(每日)',
                    prop: 'expire_daily_remind',
                },
                {
                    name: '操作',
                    type: 'slot',
                    width: '360',
                    attribute: { slotName: 'operate' },
                },
            ],
            listLoading: false,
            tgLoading: false,
        }
    },
    mounted() {
        this.getAlertData()
    },
    methods: {
        getAlertData() {
            this.listLoading = true
            alertData()
                .then((res) => {
                    this.tableData = res.data.data_info
                    this.listLoading = false
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        toPage(page, info, edit) {
            if (edit == 'edit') {
                this.$router.push({
                    path: page,
                    query: { id: info.id },
                })
            } else {
                this.$router.push({ path: page })
            }
        },
        handleDelete(data) {
            this.$confirm('确定要删除吗？', '提示', {
                type: 'warning',
            })
                .then(() => {
                    alertDeleteRobot({ id: [data.id] })
                        .then((res) => {
                            if (res.code == 200) {
                                this.$message.success('刪除成功')
                                this.getAlertData()
                            } else {
                                this.$message.error(res.message)
                            }
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                })
                .catch(() => {})
        },
        tgTest(data) {
            this.tgLoading = true
            telegramTest({ id: data.id }).then((res) => {
                if (res.code == 200) {
                    this.$message.success('測試通知')
                } else {
                    this.$message({
                        duration: 6000,
                        message: res.message,
                        type: 'error',
                    })
                }
                this.tgLoading = false
            })
        },
    },
}
</script>

<style lang="scss" scoped>
.add-btn {
    padding-bottom: 15px;
}
.red {
    color: #ff0000;
}
</style>